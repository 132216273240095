import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const DashboardSettings = Loadable(lazy(() => import("./DashboardSettings")));
const DashboardSettingsExcel = Loadable(lazy(() => import("./DashboardSettingsExcel")));

const dashboardSettingsRoutes = [
    {
        path: '/dashboard/DashboardSettings',
        element: <DashboardSettings />
    },
    {
        path: '/dashboard/DashboardSettingsExcel',
        element: <DashboardSettingsExcel />
    }
]

export default dashboardSettingsRoutes
